export default {
    data: () => ({
        optionsBar:
            {
            series: [{
                name:"Ketinggian Air",
                data: []
            }, {
                name:"Elevasi Muka Air",
                data: []
            },
            {
                name:"Debiet",
                data: []
            }],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[2001, 2002, 2003, 2004, 2005, 2006, 2007],
            chart: {
                type: 'bar',
                // stacked: false,
                height: 350,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                },
                events: {
                 
                }
            },
            plotOptions: {
                bar: {
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
                }
              },
            dataLabels: {
                enabled: true,
                // formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                //     let satuan = 'cm'
                //     if (seriesIndex == 1) {
                //         satuan = 'm'
                //     } else if(seriesIndex == 2) {
                //         satuan = 'm3/dt'
                //     }
                //   return value  + satuan
                // },
                dropShadow: {
                    enabled: true,
                    left: 2,
                    top: 2,
                    opacity: 0.2
                },
                offsetY: -20,
                  style: {
                    fontSize: '10px',
                    colors: ['#60A5FA', '#34D399', '#A78BFA'],

                  },
                  
            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            colors: ['#60A5FA', '#34D399', '#A78BFA'],

            yaxis: {
                labels:{
                    formatter: (value) => { return value.toFixed(2) },
                },
            },
            tooltip: {
                 y: {
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                        let satuan = 'mm'
                        // if (seriesIndex == 1) {
                        //     satuan = 'm'
                        // } else if(seriesIndex == 2) {
                        //     satuan = 'm3/dt'
                        // }
                      return value + ' ' + satuan
                    },
                   
                  }
            },
            
            stroke: {
                show: true,
                curve: 'stepline',
                lineCap: 'square',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}