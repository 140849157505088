<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-4">
            <div class="relative">
                      <div class="flex justify-between items-center ">
                    <div class="group inline-block relative cursor-pointer" style="min-width:250px" >
                        <div class="flex flex-wrap content-center items-center" >
                            <button class="flex flex-wrap content-center items-center justify-end "  @click="openDialog()">
                            <div class="">
                                <div class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                    <span>{{reportDevice != '' && resportDevice != {} ? reportDevice.name : 'Pilih Perangkat'}}</span>
                                </div>
                            </div>
                            </button>
                            <!-- <div class="border-l-2 border-gray-200 pl-2">
                                <unicon name="signout" class="text-lg cursor-pointer" fill="gray" @click="logout()"></unicon>
                            </div> -->
                        </div>
                        <div
                            class="shadow-lg rounded-2xl transform scale-100 text-black absolute
                        transition duration-150 ease-in-out origin-top w-full
                        bg-white flex-none
                        " v-if="dialog" style="z-index:1000000!important"
                        >
                            <div class="p-4 border-b-2 border-gray-200">
                                 <input
                                        v-model="search"
                                        type="text"
                                        :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2 text-xs `"
                                        name="name"
                                        ref="name"
                                        id="name"
                                        autocomplete="off"
                                        />
                            </div>

                            <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full">
                               
                                <div v-for="(item,index) in filteredList" :key="index" class="w-full">
                                    <div class="mb-2 px-4 flex items-center w-full mainmenu" v-if="index == 'level'">
                                        <p class="uppercase">Ketinggian Air</p>
                                       
                                     </div>
                                     <div class="mb-2 px-4 flex items-center w-full mainmenu" v-else-if="index == 'WQ'">
                                            <p class="uppercase">Kualitas Air</p> 

                                     </div>
                                     <div class="mb-2 px-4 flex items-center w-full mainmenu" v-else-if="index == 'weather'">
                                        <p class="">CUACA</p> 
                                     </div>
                                     <div class="mb-2 px-4 flex items-center w-full mainmenu" v-else-if="index == 'rain'">
                                        <p class="uppercase">Curah Hujan</p> 
                                        
                                     </div>
                                
                                    <div class="mt-2 mb-2">
                                        <div v-for="detail in item" :key="detail.id" @click="changeDeviceId(detail.id)" class="hover:bg-gray-200 py-2">
                                            <div class="ml-8 ">{{detail.name}}</div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                    
                        </div>
                    </div>
                    <div class="flex">
                        <div class="group inline-block relative cursor-pointer mr-2" >
                            <div class="flex flex-wrap content-center items-center" >
                                <button class="flex flex-wrap content-center items-center justify-end "  @click="dialog_filter = !dialog_filter">
                                    <div class="">
                                        <div class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                            <span v-if="filter == 'year'">
                                            Tahun
                                            </span>
                                            <span v-else-if="filter == 'kuartal'">
                                            Kuartal
                                            </span>
                                            <span v-else-if="filter == 'bulan'">
                                            Bulan
                                            </span>
                                            <span  v-else-if="filter == 'week'">
                                            Mingguan
                                            </span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div
                                class="shadow-lg rounded-2xl transform scale-100 text-black absolute
                            transition duration-150 ease-in-out origin-top w-full
                            bg-white flex-none
                            " v-if="dialog_filter" style="z-index:1000000!important; min-width:150px" 
                            >

                                <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full">
                                    <div class="hover:bg-gray-200 py-2" @click="changeDate('week')">
                                        <div class="ml-4 ">Mingguan</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="changeDate('bulan')">
                                        <div class="ml-4">Bulanan</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="changeDate('kuartal')">
                                        <div class="ml-4">Kuartal</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="changeDate('year')">
                                        <div class="ml-4">Tahun</div>
                                    </div>
                                </div>
                        
                            </div>
                        </div>
                        <div class="group inline-block relative cursor-pointer mr-2" >
                            <div class="flex flex-wrap content-center items-center" >
                                <button class="flex flex-wrap content-center items-center justify-end "  @click="dialog_report_button = !dialog_report_button">
                                    <div class="">
                                        <div class="flex items-center rounded-lg border border-gray-100 px-4 py-2 bg-blue-500 text-white">
                                            <unicon name="download-alt" class="mr-1" fill="white" width="18px" height="18px"></unicon>
                                            <span class="font-bold"> Unduh Laporan</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div
                                class="bg-white shadow-lg rounded-2xl transform scale-100 text-black absolute
                            transition duration-150 ease-in-out origin-top w-full
                            bg-white flex-none
                            " v-if="dialog_report_button" style="z-index:1000000!important; min-width:150px" 
                            >

                                <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full">
                                    <div class="hover:bg-gray-200 py-2" @click="generateReport()">
                                        <div class="ml-4 ">PDF</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="dialog_report = true ">
                                        <div class="ml-4">Export</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="dialog_report_group = true">
                                        <div class="ml-4">Export Grup</div>
                                    </div>
                                   
                                </div>
                        
                            </div>
                        </div>
                    
                    </div>
                </div>
                    <hr class="mt-4">
                    <div class="mt-8" slot="pdf-content" >
                        <div class="border border-gray-200 rounded-2xl p-4">
                            <div class="px-2 py-4  rounded-2xl font-bold">Rata-rata {{reportDevice ? reportDevice.name : ''}}</div>
                            <div id="chart"></div>
                        </div>


                        <div class="border border-gray-200 rounded-2xl p-4 mt-4">
                            <div class="px-2 py-4  rounded-2xl font-bold">Intensitas Curah Hujan</div>
                                <div id="level"></div>
                        </div>
                       
                        
                    </div>
                    <VueHtml2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="false"
                    :paginate-elements-by-height="1100"
                    filename="Laporan"
                    :pdf-quality="2"
                    :manual-pagination="true"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="800px"
                    ref="html2Pdf"
                    >
                    <div class="mt-4 px-4" slot="pdf-content" style="font-size:11 !important">
                        <div style="height:1000px"
                            class="w-full flex flex-col items-center justify-center html2pdf__page-break">
                            <div class="text-center">
                                <div>
                                    <img v-if="reportDevice.Customer_device && reportDevice.Customer_device.customer_id === 48"
                                        src="../../../../public/img/d1917e5df30a416bce4e48439f699a47.png" alt=""
                                        class="object-contain mx-auto" width="400px"/>
                                    <img v-else-if="reportDevice.Customer_device && reportDevice.Customer_device.customer_id === 57"
                                        src="../../../../public/img/664b4b9fd657ec899dcd9693afa842d5.png" alt=""
                                        class="h-68 object-contain mx-auto" />
                                    <img v-else src="../../../../public/default.png" alt=""
                                        class="h-68 object-contain mx-auto" />
                                </div>

                                <div class="mt-4 text-lg font-bold">
                                    <h1 class="text-3xl">
                                        Laporan {{ filterText }}
                                    </h1>
                                    <h1 class="text-3xl">
                                        {{ reportDevice ? reportDevice.name : '' }}
                                    </h1>

                                    <template
                                        v-if="reportDevice.Customer_device && reportDevice.Customer_device.customer_id === 48">
                                        <h1 class="text-3xl">Dinas Sumber Daya Air Kab Tanggerang</h1>
                                        <h1 class="text-3xl">Tahun 2025</h1>
                                    </template>

                                    <template
                                        v-else-if="reportDevice.Customer_device && reportDevice.Customer_device.customer_id === 57">
                                        <h1 class="text-3xl">BPBD Kabupaten Ogan Komering Ulu</h1>
                                        <h1 class="text-3xl">Tahun 2025</h1>
                                    </template>

                                    <template v-else>
                                        <h1 class="text-3xl">Bukan Customer</h1>
                                        <h1 class="text-3xl">Tahun 2025</h1>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div style="height:1000px" class="w-full flex flex-wrap content-center justify-center html2pdf__page-break" >
                            <div class="grid grid-cols-12 gap-4" style="font-size:11px !important">
                                <div class="border border-gray-200 col-span-12">
                                    <div class="px-2 pb-2 bg-blue-400 text-white rounded rounded-b-none font-bold" style="font-size:11px !important">Rata-rata {{reportDevice ? reportDevice.name : ''}}</div>
                                    <img :src="imageBar" alt="" srcset="">
                                </div>
                                <div class="border border-gray-200 col-span-12">
                                    <div class="px-2  pb-2 bg-blue-400 text-white rounded rounded-b-none font-bold">intensitas Curah Hujan</div>
                                    <img :src="imageChartLevel" alt="" srcset="">
                                </div>
                              
                            </div>
                            
                        </div>
                        
                       
                    </div>
                    </VueHtml2pdf>
            </div>
        </div>
        <div id="form_lamaran" v-if="dialog_report" class="absolute rounded"  style="z-index:110000 !important">
                <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                    <div class="bg-white w-full md:w-2/4 rounded">
                    <div class="bg-gray-100 p-4 rounded rounded-b-none flex justify-between items-center">
                        <p>Report Filter</p>
                        <i class="far fa-times-square text-lg cursor-pointer" @click="dialog_report = false"></i>
                    </div>
                    <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                        <div style="w-full">
                                <reportFilter :device="reportDevice" />
            
                            
                        </div>

                    </div>
                    </div>
                </div>
        </div>
        <div id="s" v-if="dialog_report_group" class="absolute rounded z-20">
                <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                    <div class="bg-white w-full md:w-1/4 rounded">
                    <div class="bg-gray-100 p-4 rounded rounded-b-none flex justify-between items-center">
                        <p>Report Filter</p>
                        <i class="far fa-times-square text-lg cursor-pointer" @click="dialog_report_group = false"></i>
                    </div>
                    <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                                <reportGroup :devices="device" />

                    </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters} from 'vuex'
import globalMixin from '../../../mixin/global'
import VueHtml2pdf from 'vue-html2pdf'
import levelChart from '../rainReportComponent/optionLevel'
// import optionElevasi from './optionElevasi'
// import optionDebit from './optionDebit'
import ApexCharts from 'apexcharts'
import lodash from 'lodash'

import barchart from './barchart'
// import optionBarLevel from './optionBarLevel'
// import optionBarElevasi from './optionBarElevasi'
// import optionBarDebit from './optionBarDebit'
var chart,chartLevel,chartElevasi,chartDebit,barElevasi,barDebit,barLevel;
var imageBar,imageBarLevel,imageBarDebit,imageBarElevasi, imageChartLevel,imageChartDebit,imageChartElevasi
import reportFilter from '../reportFilter.vue'
import reportGroup from '../reportGroup.vue'

var fileDownload = require('js-file-download');
export default {
    data: () => ({
       filter:'week',
       datas:[],
       detik:1,
       device:[],
       select_device:{name:'RANCA SUMUR'},
       device_id:'',
       imageBar:'',
       imageBarLevel:'',
       search:'',
       dialog_report_group:false,
        dialog_report:false,
       dialog_report_button:false,
       imageBarDebit:'',
       dialog_report:false,
       imageBarElevasi:'',
       imageChartLevel:'',
       imageChartDebit:'',
       imageChartElevasi:'',
       title_report:'',
       dialog:false,
       dialog_filter:false,


       status_download:false,
       update_series:false,
       default_url:process.env.VUE_APP_IMAGES,
    }),
    mixins:[globalMixin,
    barchart,
    levelChart,
    // optionElevasi,
    // optionDebit,
    // optionBarLevel,
    // optionBarElevasi,
    // optionBarDebit
    ],
    methods:{
        ...mapActions({
            setData:'data/setData',
            setStatus:'image/setStatus',
            setReportDevice:'device/setReportDevice'
        }),
        async changeDevice(){
            let item = this.device.find(x => x.id == this.device_id)
            let device = item 
            device.filter = this.filter
            this.setReportDevice(device)
            await this.getData()
        },
        async getDevice(){
            await this.axios.get('v1/device?'+`status=1`,this.config)
            .then((ress) => {
                this.device = ress.data.rows
            })
            .catch((err) => {
                this.errorResponse(err)
            })
        },

        async getData() {
            let debit = []
            let real_ketinggian = []
            let elevasi_muka_air = []
            let precipitation = []
            let tgl = []
            // this.select_device = this.device.find(x => x.id == this.reportDevice.id)
            await this.axios.get('v1/device_data/report/'+this.reportDevice.id+'?filter=' + this.reportDevice.filter,this.config)
            .then(async (ress) => {
               
               await ress.data.data.forEach(element => {
               
                precipitation.push(parseFloat(element.precipitation).toFixed(2))
                   tgl.push(element.tgl)
               });

                await this.$set(this.optionsBar.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await chart.dataURI();
                            imageBar = base.imgURI
                        }
                })

                // set data
                await this.$set( this.optionsBar,'series',[
                {name:'intensitas Curah Hujan',
                    data:precipitation
                },
                ])
                await this.$set( this.optionsBar,'labels',tgl)

            
            })
            if (this.update_series == false) {
                chart = new ApexCharts(document.querySelector("#chart"), this.optionsBar);
             
                chart.render();
            
            } else {
                // chart.updateSeries([{data:real_ketinggian},{data:elevasi_muka_air},{data:debit}])
                chart.updateOptions(this.optionsBar)
               
            }
            await this.getDeviceData()

            this.update_series = true
        },
        async getDeviceData() {
                // document.querySelector("#level").innerHTML = ''
                // document.querySelector("#elevasi").innerHTML = ''
                // document.querySelector("#debit").innerHTML = ''
                let dataReal = []
                let dataDebit = []
                let dataElevasi = []
                let precipitation = []
                await this.axios.get('v1/device_data/report/'+this.reportDevice.id+'/detail?filter=' + this.reportDevice.filter,this.config)
                .then(async (ress) => {
                    precipitation = ress.data.precipitation
                  
                })
                // data series
                await this.$set(this.optionsReal,'series',[{name:'intensitas curah hujan',data:precipitation}])
               
                // image
                await this.$set(this.optionsReal.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await chartLevel.dataURI();
                            imageChartLevel = base.imgURI
                            console.log(imageChartLevel)
                        }
                })
      

                if (this.update_series == false) {
                    
                    chartLevel = new ApexCharts(document.querySelector("#level"), this.optionsReal);
        
                    await chartLevel.render();
                
                } else {
                    await chartLevel.updateOptions(this.optionsReal)
                    
                }

                
        },
        async reportExcel() {
            this.config.responseType = 'blob'
            this.axios.get(`v1/export_file?filter=${this.reportDevice.filter}&id=${this.reportDevice.id}`, this.config)
            .then(ress => { 
                fileDownload(ress.data,'Report.xlsx')
            })
        },
        async changeDate(filter) {
            this.filter = filter
            this.changeDevice()
        },
          openDialog() {
            this.dialog = !this.dialog
        },
        async changeDeviceId(id){
            this.device_id = id
            let item = this.device.find(x => x.id == this.device_id)
            let device = item 
            device.filter = this.filter
            this.setReportDevice(device)
            this.openDialog()
            await this.getData()
        },
        async generateReport () {
            this.imageBar = imageBar
            this.imageBarElevasi = imageBarElevasi
            this.imageBarLevel = imageBarLevel
            this.imageBarDebit = imageBarDebit
            this.imageChartLevel = imageChartLevel
            this.imageChartDebit = imageChartDebit
            this.imageChartElevasi = imageChartElevasi
            await this.$refs.html2Pdf.generatePdf()
            // await this.$refs.html2Pdf.generatePdf()
            // this.status_download = false
        }
    
    },
    computed:{
        ...mapGetters({
            reportDevice:'device/reportDevice'
        }),
        filterText() {
        switch (this.filter) {
            case 'year': return 'Tahun ini';
            case 'year_ago': return 'Tahun lalu';
            case 'kuartal': return 'Kuartal';
            case 'bulan': return 'Bulan';
            case 'week': return 'Mingguan';
            case 'day': return 'Harian';
            default: return 'Tahun 2025';
        }
    },
      filteredList() {
        		if(this.search === '') return lodash.groupBy(this.device,'type')
            return lodash.groupBy(this.device.filter(entry => {
                return entry.name.toLowerCase().includes(this.search.toLowerCase())
            }),'type')   
        }
    },
    watch:{
      
    },
    components:{
        VueHtml2pdf,reportFilter,reportGroup
    },
    async created() {
        await this.getDevice()
        if (this.reportDevice) {
            this.device_id = this.reportDevice.id
            this.changeDevice()
        }

    },
    mounted() {
       
    }
    
}
</script>