<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="relative">
                <div class="flex justify-between items-center ">
                    <div class="group inline-block relative cursor-pointer" style="min-width:250px" >
                        <div class="flex flex-wrap content-center items-center" >
                            <button class="flex flex-wrap content-center items-center justify-end" :class="{'animate-bounce': reportDevice == '' | reportDevice == {}}" @click="openDialog()">
                                <div class="">
                                    <div class="flex items-center rounded-lg border px-4 py-2"  :class="{ 'border-gray-100': reportDevice, 'border-blue-500': !reportDevice }">
                                        <span>{{reportDevice != '' && reportDevice != {} ? reportDevice.name : 'Pilih Perangkat'}}</span>
                                    </div>
                                </div>
                            </button>
                            <!-- <div class="border-l-2 border-gray-200 pl-2">
                                <unicon name="signout" class="text-lg cursor-pointer" fill="gray" @click="logout()"></unicon>
                            </div> -->
                        </div>
                        <div
                            class="shadow-lg rounded-2xl transform scale-100 text-black absolute
                        transition duration-150 ease-in-out origin-top w-full
                        bg-white flex-none
                        " v-if="dialog" style="z-index:1000000!important"
                        >
                            <div class="p-4 border-b-2 border-gray-200">
                                 <input
                                        v-model="search"
                                        type="text"
                                        :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2 text-xs `"
                                        name="name"
                                        ref="name"
                                        id="name"
                                        autocomplete="off"
                                        />
                            </div>

                            <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full">
                               
                                <div v-for="(item,index) in filteredList" :key="index" class="w-full">
                                    <div class="mb-2 px-4 flex items-center w-full mainmenu" v-if="index == 'level'">
                                        <p class="uppercase">Ketinggian Air</p>
                                       
                                     </div>
                                     <div class="mb-2 px-4 flex items-center w-full mainmenu" v-else-if="index == 'WQ'">
                                            <p class="uppercase">Kualitas Air</p> 

                                     </div>
                                     <div class="mb-2 px-4 flex items-center w-full mainmenu" v-else-if="index == 'weather'">
                                        <p class="">CUACA</p> 
                                     </div>
                                     <div class="mb-2 px-4 flex items-center w-full mainmenu" v-else-if="index == 'rain'">
                                        <p class="uppercase">Curah Hujan</p> 
                                        
                                     </div>
                                
                                    <div class="mt-2 mb-2">
                                        <div v-for="detail in item" :key="detail.id" @click="changeDeviceId(detail.id)" class="hover:bg-gray-200 py-2">
                                            <div class="ml-8 ">{{detail.name}}</div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                    
                        </div>
                    </div>
                    <div class="flex">
                        <div class="group inline-block relative cursor-pointer mr-2" >
                            <div class="flex flex-wrap content-center items-center" >
                                <button class="flex flex-wrap content-center items-center justify-end "  @click="dialog_filter = !dialog_filter">
                                    <div class="">
                                        <div class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                            <span v-if="filter == 'year'">
                                            Tahun ini
                                            </span>
                                            <span v-else-if="filter == 'year_ago'">
                                            Tahun lalu
                                            </span>
                                            <span v-else-if="filter == 'kuartal'">
                                            Kuartal
                                            </span>
                                            <span v-else-if="filter == 'bulan'">
                                            Bulan ini
                                            </span>
                                            <span v-else-if="filter == 'bulan_lalu'">
                                            Bulan Lalu
                                            </span>
                                            <span  v-else-if="filter == 'week'">
                                            Mingguan
                                            </span>
                                            <span  v-else-if="filter == 'day'">
                                            Harian
                                            </span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div
                                class="bg-white shadow-lg rounded-2xl transform scale-100 text-black absolute
                            transition duration-150 ease-in-out origin-top w-full
                            bg-white flex-none
                            " v-if="dialog_filter" style="z-index:1000000!important; min-width:150px" 
                            >

                                <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full">
                                    <div class="hover:bg-gray-200 py-2" @click="changeDate('day')">
                                        <div class="ml-4">Harian</div>
                                    </div>
                                    <div class="hover:bg-gray-200 py-2" @click="changeDate('week')">
                                        <div class="ml-4 ">Mingguan</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="changeDate('bulan')">
                                        <div class="ml-4">Bulan ini</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="changeDate('bulan_lalu')">
                                        <div class="ml-4">Bulan Lalu</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="changeDate('kuartal')">
                                        <div class="ml-4">Kuartal</div>
                                    </div>
                                    <div class="hover:bg-gray-200 py-2" @click="changeDate('year')">
                                        <div class="ml-4">Tahun ini</div>
                                    </div>
                                    <div class="hover:bg-gray-200 py-2" @click="changeDate('year_ago')">
                                        <div class="ml-4">Tahun lalu</div>
                                    </div>
                                </div>
                        
                            </div>
                        </div>
                        <div class="group inline-block relative cursor-pointer mr-2" >
                            <div class="flex flex-wrap content-center items-center" >
                                <button class="flex flex-wrap content-center items-center justify-end "  @click="dialog_report_button = !dialog_report_button">
                                    <div class="">
                                        <div class="flex items-center rounded-lg border border-gray-100 px-4 py-2 bg-blue-500 text-white">
                                            <unicon name="download-alt" class="mr-1" fill="white" width="18px" height="18px"></unicon>
                                            <span class="font-bold"> Unduh Laporan</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div
                                class="bg-white shadow-lg rounded-2xl transform scale-100 text-black absolute
                            transition duration-150 ease-in-out origin-top w-full
                            bg-white flex-none
                            " v-if="dialog_report_button" style="z-index:1000000!important; min-width:150px" 
                            >

                                <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full">
                                    <div class="hover:bg-gray-200 py-2" @click="generateReport()">
                                        <div class="ml-4 ">PDF</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="dialog_report = true ">
                                        <div class="ml-4">Export</div>
                                    </div>
                                     <div class="hover:bg-gray-200 py-2" @click="dialog_report_group = true">
                                        <div class="ml-4">Export Grup</div>
                                    </div>
                                   
                                </div>
                                
                            </div>
                        </div>
                    
                    </div>
                </div>
                    <hr class="mt-4">
                    <!-- <div class="mt-8 border broder-gray-200 border-t-0 border-r-0 border-l-0 grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-9">
                            <button class="rounded text-xs md:text-sm hover:bg-gray-100 rounded-b-none px-4 py-2 mr-1" :class="filter == 'year' ? 'bg-gray-100' : ''" @click="changeDate('year')">
                            Tahun
                            </button>
                            <button class="rounded text-xs md:text-sm hover:bg-gray-100 rounded-b-none px-4 py-2 mr-1" :class="filter == 'kuartal' ? 'bg-gray-100' : ''" @click="changeDate('kuartal')">
                            Kuartal
                            </button>
                            <button class="rounded text-xs md:text-sm hover:bg-gray-100 rounded-b-none px-4 py-2 mr-1" :class="filter == 'bulan' ? 'bg-gray-100' : ''" @click="changeDate('bulan')">
                            Bulan
                            </button>
                            <button class="rounded text-xs md:text-sm hover:bg-gray-100 rounded-b-none px-4 py-2 mr-1" :class="filter == 'week' ? 'bg-gray-100' : ''" @click="changeDate('week')">
                            Seminggu
                            </button>

                            <select class="w-full md:w-48" name="" id="" v-model="device_id" @change="changeDevice">
                                <option :value="item.id" v-for="item in device" :key="item.id" >{{item.name}}</option>
                            </select>
                        </div>
                        <div class="col-span-12 md:col-span-3 flex flex-wrap content-center">
                        <button class="bg-green-400 text-white px-4 rounded shadow hover:bg-green-600 py-2 md:mr-2" @click="generateReport()">Cetak</button>
                        <button class="bg-green-400 text-white px-4 rounded shadow hover:bg-green-600 py-2 md:mr-2" @click="dialog_report = true ">Export</button> 
                        <button class="bg-green-400 text-white px-4 rounded shadow hover:bg-green-600 py-2" @click="dialog_report_group = true">Grub Export</button> 

                        </div>
                    </div> -->
                    <div class="mt-8" slot="pdf-content" >
                        <div class="border border-gray-200 rounded-2xl p-4">
                            <div class="px-2 py-4 rounded-2xl rounded-b-none font-bold">Rata-rata {{reportDevice ? reportDevice.name : ''}}</div>
                            <div id="chart"></div>
                        </div>

                        <div class="grid grid-cols-12 gap-4 mt-4">
                            <div class="col-span-12 md:col-span-6 border border-gray-200 rounded-2xl p-4">
                                <div class="px-2 py-4 rounded-2xl rounded-b-none font-bold">Ketinggian Air</div>
                                <div id="level"></div>
                            </div>
                             <div class="col-span-12 md:col-span-6 border border-gray-200 rounded-2xl p-4">
                                <div class="px-2 py-4 rounded-2xl rounded-b-none font-bold">Ketinggian Air</div>
                                <div id="barlevel"></div>
                            </div>
                            <div class="col-span-12 md:col-span-6  border border-gray-200 rounded-2xl p-4">
                                <div class="px-2 py-4 rounded-2xl rounded-b-none font-bold flex justify-between">
                                    Elevasi Muka Air
                                </div>
                                <div id="elevasi"></div>
                            </div>
                             <div class="col-span-12 md:col-span-6  border border-gray-200 rounded-2xl p-4">
                                <div class="px-2 py-4 rounded-2xl rounded-b-none font-bold">Elevasi Muka Air</div>
                                <div id="barelevasi"></div>
                            </div>
                             <div class="col-span-12 md:col-span-6  border border-gray-200 rounded-2xl p-4">
                                <div class="px-2 py-4 rounded-2xl rounded-b-none font-bold">Debiet Q</div>
                                <div id="debit"></div>

                            </div>
                             <div class="col-span-12 md:col-span-6  border border-gray-200 rounded-2xl p-4">
                                <div class="px-2 py-4 rounded-2xl rounded-b-none font-bold">Debiet Q</div>
                                <div id="bardebit"></div>

                            </div>
                        </div>
                        
                    </div>
                    <VueHtml2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="false"
                    :paginate-elements-by-height="1100"
                    filename="Laporan"
                    :pdf-quality="2"
                    :manual-pagination="true"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="800px"
                    ref="html2Pdf"
                    >
                    <div class="mt-4 px-4" slot="pdf-content" style="font-size:11 !important">
                        <div style="height:1000px"
                            class="w-full flex flex-col items-center justify-center html2pdf__page-break">
                            <div class="text-center">
                                <div>
                                    <img v-if="reportDevice.Customer_device && reportDevice.Customer_device.customer_id === 48"
                                        src="../../../../public/img/d1917e5df30a416bce4e48439f699a47.png" alt=""
                                        class="object-contain mx-auto" width="400px"/>
                                    <img v-else-if="reportDevice.Customer_device && reportDevice.Customer_device.customer_id === 57"
                                        src="../../../../public/img/664b4b9fd657ec899dcd9693afa842d5.png" alt=""
                                        class="h-68 object-contain mx-auto" />
                                    <img v-else src="../../../../public/default.png" alt=""
                                        class="h-68 object-contain mx-auto" />
                                </div>

                                <div class="mt-4 text-lg font-bold">
                                    <h1 class="text-3xl">
                                        Laporan {{ filterText }}
                                    </h1>
                                    <h1 class="text-3xl">
                                        {{ reportDevice ? reportDevice.name : '' }}
                                    </h1>

                                    <template
                                        v-if="reportDevice.Customer_device && reportDevice.Customer_device.customer_id === 48">
                                        <h1 class="text-3xl">Dinas Sumber Daya Air Kab Tanggerang</h1>
                                        <h1 class="text-3xl">Tahun 2025</h1>
                                    </template>

                                    <template
                                        v-else-if="reportDevice.Customer_device && reportDevice.Customer_device.customer_id === 57">
                                        <h1 class="text-3xl">BPBD Kabupaten Ogan Komering Ulu</h1>
                                        <h1 class="text-3xl">Tahun 2025</h1>
                                    </template>

                                    <template v-else>
                                        <h1 class="text-3xl">Bukan Customer</h1>
                                        <h1 class="text-3xl">Tahun 2025</h1>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div style="height:1000px" class="w-full flex flex-wrap content-center justify-center html2pdf__page-break" >
                            <div class="grid grid-cols-12 gap-4" style="font-size:11px !important">
                                <div class="border border-gray-200 rounded-2xl p-4 col-span-12">
                                    <div class="px-2 pb-2 rounded-2xl rounded-b-none font-bold" style="font-size:11px !important">Rata-rata {{reportDevice ? reportDevice.name : ''}}</div>
                                    <img :src="imageBar" alt="" srcset="">
                                </div>
                                <div class="col-span-6 border border-gray-200 rounded-2xl p-4">
                                    <div class="px-2  pb-2 rounded-2xl rounded-b-none font-bold">Ketinggian Air</div>
                                    <img :src="imageChartLevel" alt="" srcset="">
                                </div>
                                <div class="col-span-6 border border-gray-200 rounded-2xl p-4">
                                    <div class="px-2  pb-2 rounded-2xl rounded-b-none font-bold">Ketinggian Air</div>
                                    <img :src="imageBarLevel" alt="" srcset="">
                                </div>

                                 <div class="col-span-6 border border-gray-200 rounded-2xl p-4">
                                    <div class="px-2  pb-2 rounded-2xl rounded-b-none font-bold">Elemasi Muka Air</div>
                                    <img :src="imageChartElevasi" alt="" srcset="">
                                </div>
                                <div class="col-span-6 border border-gray-200 rounded-2xl p-4">
                                    <div class="px-2  pb-2 rounded-2xl rounded-b-none font-bold">Elemasi Muka Air</div>
                                    <img :src="imageBarElevasi" alt="" srcset="">
                                </div>

                                 <div class="col-span-6 border border-gray-200 rounded-2xl p-4">
                                    <div class="px-2  pb-2 rounded-2xl rounded-b-none font-bold">Debiet Q</div>
                                    <img :src="imageChartDebit" alt="" srcset="">
                                </div>
                                <div class="col-span-6 border border-gray-200 rounded-2xl p-4">
                                    <div class="px-2  pb-2 rounded-2xl rounded-b-none font-bold">Debiet Q</div>
                                    <img :src="imageBarDebit" alt="" srcset="">
                                </div>
                            </div>
                            
                        </div>
                        
                       
                    </div>
                    </VueHtml2pdf>
            </div>
        </div>
           <div id="form_lamaran" v-if="dialog_report" class="absolute rounded"  style="z-index:110000 !important">
                <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                    <div class="bg-white w-full md:w-2/4 rounded">
                    <div class="bg-gray-100 p-4 rounded-2xl rounded-b-none flex justify-between items-center">
                        <p>Report Filter</p>
                        <i class="far fa-times-square text-lg cursor-pointer" @click="dialog_report = false"></i>
                    </div>
                    <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                        <div style="w-full">
                                <reportFilter :device="reportDevice" />
                            
                        </div>

                    </div>
                    </div>
                </div>
            </div>
             <div id="s" v-if="dialog_report_group" class="absolute rounded z-20">
                <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                    <div class="bg-white w-full md:w-1/4 rounded">
                    <div class="bg-gray-100 p-4 rounded-2xl rounded-b-none flex justify-between items-center">
                        <p>Report Filter</p>
                        <i class="far fa-times-square text-lg cursor-pointer" @click="dialog_report_group = false"></i>
                    </div>
                    <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                                <reportGroup :devices="device" />

                    </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<style scoped>
    .mainmenu:after{
  background: #E8EAF0;
  height: 1px;
  flex: 1;
  content: '';
  padding-left: 2px;
}
.mainmenu > p:after {
  background: none;
}
</style>
<script>
import { mapActions, mapGetters} from 'vuex'
import globalMixin from '../../../mixin/global'
import VueHtml2pdf from 'vue-html2pdf'
import levelChart from './optionLevel'
import optionElevasi from './optionElevasi'
import optionDebit from './optionDebit'
import ApexCharts from 'apexcharts'

import barchart from './barchart'
import optionBarLevel from './optionBarLevel'
import optionBarElevasi from './optionBarElevasi'
import optionBarDebit from './optionBarDebit'
import reportFilter from '../reportFilter.vue'
import reportGroup from '../reportGroup.vue'
import lodash from 'lodash'
var chart,chartLevel,chartElevasi,chartDebit,barElevasi,barDebit,barLevel;
var imageBar,imageBarLevel,imageBarDebit,imageBarElevasi, imageChartLevel,imageChartDebit,imageChartElevasi

var fileDownload = require('js-file-download');
export default {
    data: () => ({
       filter:'week',
       datas:[],
       dialog_report:false,
       dialog_report_button:false,
       detik:1,
       device:[],
       select_device:{name:'RANCA SUMUR'},
       device_id:'',
       imageBar:'',
       imageBarLevel:'',
       imageBarDebit:'',
       search:'',
       imageBarElevasi:'',
       dialog:false,
       dialog_report_group:false,
       imageChartLevel:'',
       imageChartDebit:'',
       imageChartElevasi:'',
       dialog_filter:false,
       title_report:'',
       status_download:false,
       update_series:false,
       default_url:process.env.VUE_APP_IMAGES,
    }),
    mixins:[globalMixin,barchart,levelChart,optionElevasi,optionDebit,optionBarLevel,optionBarElevasi,optionBarDebit],
    methods:{
        ...mapActions({
            setData:'data/setData',
            setStatus:'image/setStatus',
            setReportDevice:'device/setReportDevice'
        }),
        async changeDevice(){
            let item = this.device.find(x => x.id == this.device_id)
            let device = item 
            device.filter = this.filter
            this.setReportDevice(device)
            await this.getData()
        },
        async changeDeviceId(id){
            this.device_id = id
            let item = this.device.find(x => x.id == this.device_id)
            let device = item 
            device.filter = this.filter
            this.setReportDevice(device)
            this.openDialog()
            await this.getData()
        },
        
        async getDevice(){
            await this.axios.get('v1/device?'+`status=1`,this.config)
            .then((ress) => {
                this.device = ress.data.rows
            })
            .catch((err) => {
                this.errorResponse(err)
            })
        },

        openDialog() {
            this.dialog = !this.dialog
        },

        async getData() {
            try {
                let debit = [];
                let real_ketinggian = [];
                let elevasi_muka_air = [];
                let tgl = [];

                // Paralel request laporan & users
                const [reportRes, usersRes] = await Promise.all([
                    this.axios.get(`v1/device_data/report/${this.reportDevice.id}?filter=${this.reportDevice.filter}&year=${this.reportDevice.year}`, this.config),
                    this.axios.get('v1/users', this.config)
                ]);

                // Olah data laporan
                
                const processedData = reportRes.data.data.map(element => ({
                    debit: element.debit ? parseFloat(element.debit).toFixed(2) : 0,
                    real_ketinggian: parseFloat(element.real_ketinggian * 100).toFixed(2),
                    elevasi_muka_air: parseFloat(element.elevasi_muka_air).toFixed(2),
                    tgl: element.tgl
                }));
                debit = processedData.map(e => e.debit);
                real_ketinggian = processedData.map(e => e.real_ketinggian);
                elevasi_muka_air = processedData.map(e => e.elevasi_muka_air);
                tgl = processedData.map(e => e.tgl);

                // Set data pengguna
                const user = usersRes.data.rows.find(user => user.id === this.reportDevice.user_id);
                if (user) {
                    this.reportDevice.name = user.name;
                }

                await this.$set( this.optionsBar,'series',[
                {name:'Ketinggian Air',
                    data:real_ketinggian
                },
                {name:'Elevasi Muka Air',
                    data:elevasi_muka_air,
                },
                {name:'Debiet',
                    data:debit,

                }
                ])
                await this.$set(this.optionBarLevel,'series',[  {name:'Ketinggian Air',
                    data:real_ketinggian
                }])
                await this.$set(this.optionBarElevasi,'series',[  {name:'Elevasi Muka Air',
                    data:elevasi_muka_air,
                }])
                 await this.$set(this.optionBarDebit,'series',[  {name:'Debiet',
                    data:debit,
                }])

                // set label
                await this.$set(this.optionBarLevel,'labels',tgl)
                await this.$set(this.optionBarElevasi,'labels',tgl)
                await this.$set(this.optionBarDebit,'labels',tgl)
                await this.$set( this.optionsBar,'labels',tgl)

                // set image
                await this.$set(this.optionsBar.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await chart.dataURI();
                            imageBar = base.imgURI
                        }
                })
                await this.$set(this.optionBarLevel.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await barLevel.dataURI();
                            imageBarLevel = base.imgURI
                        }
                })
                await this.$set(this.optionBarDebit.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await barDebit.dataURI();
                            imageBarDebit = base.imgURI
                        }
                })
                await this.$set(this.optionBarElevasi.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await barElevasi.dataURI();
                            imageBarElevasi = base.imgURI
                        }
                })

        // Render atau Update Chart
            if (this.update_series == false) {
                chart = new ApexCharts(document.querySelector("#chart"), this.optionsBar);
                barLevel = new ApexCharts(document.querySelector("#barlevel"), this.optionBarLevel);
                barElevasi = new ApexCharts(document.querySelector("#barelevasi"), this.optionBarElevasi);
                barDebit = new ApexCharts(document.querySelector("#bardebit"), this.optionBarDebit);
                chart.render();
                barLevel.render()
                barElevasi.render()
                barDebit.render()
            } else {
                // chart.updateSeries([{data:real_ketinggian},{data:elevasi_muka_air},{data:debit}])
                chart.updateOptions(this.optionsBar)
                barLevel.updateOptions(this.optionBarLevel)
                barElevasi.updateOptions(this.optionBarElevasi)
                barDebit.updateOptions(this.optionBarDebit)
            }

            // Update device data terakhir
            await this.getDeviceData();

            this.update_series = true;
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        async getDeviceData() {
                // document.querySelector("#level").innerHTML = ''
                // document.querySelector("#elevasi").innerHTML = ''
                // document.querySelector("#debit").innerHTML = ''
                let dataReal = []
                let dataDebit = []
                let dataElevasi = []
                await this.axios.get('v1/device_data/report/'+this.reportDevice.id+'/detail?filter=' + this.reportDevice.filter + '&year=' + this.reportDevice.year,this.config)
                .then(async (ress) => {
                    dataReal = ress.data.data_real
                    dataElevasi = ress.data.data_elevasi
                    dataDebit = ress.data.data_debit
                })
                // data series
                await this.$set(this.optionsReal,'series',[{name:'Ketinggian Air [cm]',data:dataReal}])
                await this.$set(this.optionsElevasi,'series',[{name:'Elevasi Muka Air [m]',data:dataElevasi}])
                await this.$set(this.optionsDebit,'series',[{name:'Debiet Q',data:dataDebit}])

                // image
                await this.$set(this.optionsReal.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await chartLevel.dataURI();
                            imageChartLevel = base.imgURI
                            console.log(imageChartLevel)
                        }
                })
                await this.$set(this.optionsElevasi.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await chartElevasi.dataURI();
                            imageChartElevasi = base.imgURI
                        }
                })
                await this.$set(this.optionsDebit.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await chartDebit.dataURI();
                            imageChartDebit = base.imgURI
                        }
                })
                

                if (this.update_series == false) {
                    
                    chartLevel = new ApexCharts(document.querySelector("#level"), this.optionsReal);
                    chartElevasi = new ApexCharts(document.querySelector("#elevasi"), this.optionsElevasi);
                    chartDebit = new ApexCharts(document.querySelector("#debit"), this.optionsDebit);
                    await chartLevel.render();
                    await chartDebit.render();
                    await chartElevasi.render();
                } else {
                    await chartLevel.updateOptions(this.optionsReal)
                    await chartDebit.updateOptions(this.optionsDebit)
                    await chartElevasi.updateOptions(this.optionsElevasi)
                }

                
        },
        async reportExcel() {
            this.config.responseType = 'blob'
            this.axios.get(`v1/export_file?filter=${this.reportDevice.filter}&id=${this.reportDevice.id}`, this.config)
            .then(ress => { 
                fileDownload(ress.data,'Report.xlsx')
            })
        },
        closeDialogReport() {
            this.dialog_report = false
        },
        async changeDate(filter) {
            this.filter = filter
            if (filter === 'year_ago') {
                // Jika 'year_ago' dipilih, hitung tahun lalu
                const lastYear = new Date().getFullYear() - 1;
                this.reportDevice.year = lastYear;  // Menyimpan tahun lalu dalam `reportDevice.year`
            } else if (filter === 'year') {
                // Jika 'year' dipilih, gunakan tahun sekarang
                this.reportDevice.year = new Date().getFullYear();
            }
            this.changeDevice()
            this.dialog_filter = false
        },
        async generateReport () {
            this.imageBar = imageBar
            this.imageBarElevasi = imageBarElevasi
            this.imageBarLevel = imageBarLevel
            this.imageBarDebit = imageBarDebit
            this.imageChartLevel = imageChartLevel
            this.imageChartDebit = imageChartDebit
            this.imageChartElevasi = imageChartElevasi
            await this.$refs.html2Pdf.generatePdf()
            // await this.$refs.html2Pdf.generatePdf()
            // this.status_download = false
        }
    
    },
    computed:{
        ...mapGetters({
            reportDevice:'device/reportDevice'
        }),
        filterText() {
        switch (this.filter) {
            case 'year': return 'Tahun Ini';
            case 'year_ago': return 'Tahun Lalu';
            case 'kuartal': return 'Kuartal';
            case 'bulan': return 'Bulanan';
            case 'bulan_lalu': return 'Bulan Lalu';
            case 'week': return 'Mingguan';
            case 'day': return 'Hari Ini';
            default: return 'Tahun 2025';
        }
    },
        filteredList() {
        		if(this.search === '') return lodash.groupBy(this.device,'type')
            return lodash.groupBy(this.device.filter(entry => {
                return entry.name.toLowerCase().includes(this.search.toLowerCase())
            }),'type')   
        }
      
    },
    watch:{
      
    },
    components:{
        VueHtml2pdf,reportFilter,reportGroup
    },
    async created() {
        await this.getDevice()
        if (this.reportDevice) {
            this.device_id = this.reportDevice.id
            this.changeDevice()
        }

    },
    mounted() {
       
    }
    
}
</script>